<template>
	<BaseLayout>
		<Toolbar @clickLeft="$router.push('/account')">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ $t('wishList.myWishList') }}</p>
		</Toolbar>
		<div v-if="wishlist.length > 0" class="h-fit min-h-screen overflow-x-scroll bg-light-blue pb-20">
			<div class="grid grid-cols-2 gap-5 p-4">
				<div v-for="product in wishlist" :key="product.id" class="relative rounded-md bg-white">
					<div v-if="product.isSoldOut" class="absolute z-30 inline-flex h-full w-full items-center justify-center rounded-md bg-secondary bg-opacity-60 text-3xl text-white">售罄</div>

					<!-- 商品資料 -->
					<div class="flex h-full flex-col justify-between rounded-md bg-white" @click="setProduct(product)">
						<div class="space-y-2">
							<img class="h-40 w-full rounded-t-md object-cover" :src="product.imageUrls[0]" alt="" loading="lazy" />
							<div class="px-2">
								<!-- <p class="text-xs text-gray-400">{{ product.sku }}</p> -->
								<p class="text-sm font-bold">{{ product.name[currentLang] }}</p>
							</div>
						</div>

						<div class="text-md relative p-2 font-bold">
							<div class="relative">
								<!-- 切換價錢 -->
								<p v-if="product.variants[0].isCustomPrice" class="text-primary">{{ $t('categories.quoteSeparately') }}</p>
								<p v-else-if="product.variants[0].originalPrice[saleMethod] > product.variants[0].sellingPrice[saleMethod]" class="flex flex-col text-red-500">
									<del class="text-xs text-muted">HK ${{ product.variants[0].originalPrice[saleMethod] }}</del
									>HK ${{ product.variants[0].sellingPrice[saleMethod] }}
								</p>
								<p v-else class="text-primary">HK ${{ product.variants[0].sellingPrice[saleMethod] }}</p>
								<!-- 已加入購物車數量 -->
								<div v-if="inCart(product.id)" class="absolute bottom-0 right-0 inline-flex h-6 w-6 items-center justify-center rounded-2xl bg-primary text-white">
									{{ inCart(product.id)?.product.orderedQuantity }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="flex h-3/4 w-full items-center justify-center">
			<p>{{ $t('wishList.emptyWishList') }}</p>
		</div>
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
// import { computed } from 'vue'
// import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useCustomerStore } from '@/store/customer'
import { useCatalogStore } from '@/store/catalog'
import { useCartStore } from '@/store/cart'

export default {
	setup() {
		const router = useRouter()
		// const store = useStore()

		const { currentLang } = storeToRefs(useSharedStore())

		const { wishlist } = storeToRefs(useCustomerStore())
		const { product } = storeToRefs(useCatalogStore())
		const { currentProduct, saleMethod } = storeToRefs(useCartStore())
		const { setCurrentProduct, setCurrentVariant, inCart } = useCartStore()

		// const wishlist = computed(() => currentCustomer.favoriteProductIds.forEach(favoriteProductId))

		// function cartQuantity(id) {
		// 	return store.getters.cartProductQuantity(id)
		// }

		function setProduct(val) {
			product.value = val
			if (inCart(val.id)) {
				const { product, index } = inCart(val.id)
				currentProduct.value = product
				return router.push(`/products/${val.id}?index=${index}`)
			}

			setCurrentProduct(val)
			setCurrentVariant(val.variants[0])
			router.push(`/products/${val.id}`)
		}

		return {
			currentLang,
			setProduct,
			wishlist,
			// cartQuantity,
			saleMethod,
			inCart,
		}
	},
}
</script>
